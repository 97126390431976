var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasUser)?_c('div',{staticClass:"layout m-xl",class:{ 'wrapper--no-padding row': _vm.isEmbedded, wrapper: !_vm.isEmbedded }},[(_vm.isEmbedded)?_c('div',{staticClass:"layout__aside col-xxxs-14 col-s-4 col-l-3 col-xxxs-offset-1"},[_c('SidebarNav',{staticClass:"layout__aside__sidebar"}),_c('div',{staticClass:"layout__aside__faq"},[_c('div',{staticClass:"layout__aside__faq__title"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"icons-compass"}}),_vm._v(" "+_vm._s(_vm.$t('dashboard.faq.title'))+" ")]),_c('span',{staticClass:"layout__aside__faq__text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.faq.text'))+" ")]),_c('g-btn',{staticClass:"btn--outline",attrs:{"btn":{
          icon: {
            symbol: 'icons-arrow-front',
            size: '0 0 20 21',
          },
          url: _vm.$t('dashboard.faq.url'),
        },"tag":"a","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.faq.link'))+" ")])],1)],1):_vm._e(),_c('div',{class:{
      'col-xxxs-14 col-s-8 col-l-9 col-xxxs-offset-1': _vm.isEmbedded,
    }},[_vm._t("default")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }