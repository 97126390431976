























































import documents from '@/_dev/documents'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import { isDashboardEnabled } from '@/composables/ui'

import Layout from '@/components/Layout.vue'
import DocumentList from '@/components/DocumentList.vue'

export default defineComponent({
  name: 'sign',
  components: { DocumentList, Layout },
  props: {},

  setup(props, ctx) {
    const { FETCH_PACKAGES: fetchPackages } = useActions('user', ['FETCH_PACKAGES'])
    const result = ref<string | null>(null)
    // Display without sidebar
    const isStandAlone = ref(false)
    const { data, packages, hasUser } = useState('user', ['data', 'packages', 'hasUser'])

    onBeforeMount(async () => {
      await fetchPackages()
      result.value = ctx.root.$route.query.action as string
      isStandAlone.value = isDashboardEnabled()
    })

    return {
      isStandAlone,
      hasUser,
      data,
      packages,
      documents,
      result,
    }
  },
})
