










































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useState, useGetters } from '@u3u/vue-hooks'

import SidebarNav from '@/components/SidebarNav.vue'
import { isDashboardEnabled } from '@/composables/ui'

export default defineComponent({
  name: 'layout',
  components: { SidebarNav },
  props: {},

  setup(props, ctx) {
    const isEmbedded = ref(true)
    const routeName = ctx.root.$route.name
    const { hasUser, archivedLoans } = useState('user', ['hasUser', 'archivedLoans'])
    const { loanAmount } = useGetters('user', ['loanAmount'])

    onBeforeMount(() => {
      isEmbedded.value = isDashboardEnabled()
    })

    return { isEmbedded, routeName, hasUser, loanAmount, archivedLoans }
  },
})
