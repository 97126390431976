





























































import { useState } from '@u3u/vue-hooks'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'sidebar',
  components: {},
  props: {},

  setup(props, ctx) {
    const routeName = ctx.root.$route.name
    const { loans, mortgages } = useState('user', {
      loans: 'loans',
      mortgages: 'mortgages',
    })

    const archivedLoans = computed(() => [...loans.value, ...mortgages.value].filter(i => i.status === 'ARCHIVE'))

    return { archivedLoans, routeName }
  },
})
