





























































































































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'document-list',
  components: {},
  props: {
    content: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as () => Record<string, any>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
