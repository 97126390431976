export default [
  {
    title: 'Contrat 12345',
    documents: [
      {
        name: 'Jean Dupont',
        status: 'coemprunteur',
        link: '#',
        signed: true,
      },
      {
        name: 'Jean Dupont',
        status: 'coemprunteur',
        link: '#',
        signed: false,
      },
    ],
  },
  {
    title: 'Contrat 54321',
    documents: [
      {
        name: 'Jean Dupont',
        status: 'coemprunteur',
        link: '#',
        signed: true,
      },
      {
        name: 'Jean Dupont',
        status: 'coemprunteur',
        link: '#',
        signed: true,
      },
    ],
  },
]
